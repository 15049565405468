import { UPSELL } from '../constants';
import { GenericObjectType } from '../types';
import { ParsedMenuItem } from '../types/menu';
import { TopLevelMenuItem } from './menu';
import { GenericMap } from './types';

export const findObjectInObjectByKeyValue = (
  obj: GenericObjectType,
  keyName: string,
  keyValue: string
) => {
  return Object.values(obj).find(
    (innerObj: GenericObjectType) => innerObj[keyName] === keyValue
  );
};

export const readEnvVariable = (key: string) =>
  process.env[`REACT_APP_${key}`] || '';

export const snakeToCamelCase = (str: string): string =>
  str.replace(/[^a-zA-Z0-9]+(.)/g, (m: string, letter: string): string =>
    letter.toUpperCase()
  );

export function convertToMap<T extends { id: string | number }>(
  entries: T[]
): GenericMap<T> {
  return entries.reduce((acc, entry) => {
    acc[entry.id] = entry;
    return acc;
  }, {} as GenericMap<T>);
}

export const findAverageOfArray = (arr: Array<number>) => {
  return arr.length ? arr.reduce((p, c) => p + c, 0) / arr.length : 0;
};

export const isMenuItemAnUpsell = (
  menuItem: ParsedMenuItem | TopLevelMenuItem
) => {
  return (
    menuItem?.category?.startsWith(UPSELL) || menuItem?.name?.startsWith(UPSELL)
  );
};
